import React, { useState } from 'react'
import InputFieldItem from './InputFieldItem'
import './style.css'
import { SiteConfig } from '../../types/'
import submitForm from './submitForm'
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'
import validateFormFields from '../../utils/validateFormFields'
import { ThemeOptions, InputFieldDTO } from '../../types'

const inputFields: InputFieldDTO[] = [
    { field: 'First Name', type: 'text', required: true },
    { field: 'Last Name', type: 'text', required: true },
    { field: 'Email', type: 'email', required: true },
    { field: 'Phone', type: 'tel', required: true },
]
const enquireFormBackgroundImage: string = require('../../images/form_background_image.png')

interface Props {
    home: string
    options: ThemeOptions
    config: SiteConfig
}

export default ({ options, config, home = '' }: Props) => {
    const [inputs, setInputs] = useState<string[]>(['', '', '', ''])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [recaptcha, setRecaptcha] = useState<boolean>(false)
    const [checkbox, setCheckbox] = useState<boolean>(false)
    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError('')

        const firstName = inputs[0]
        const lastName = inputs[1]
        const email = inputs[2]
        const phone = inputs[3]

        const validated: boolean = validateFormFields(email)

        if (!recaptcha || !checkbox || !validated) {
            setError('Please fill out the fields correctly')
            return
        }
        setLoading(true)
        submitForm(firstName, lastName, email, phone, home, config)
            .then(() => {
                setLoading(false)
                navigate('/thank-you')
            })
            .catch(error => {
                setLoading(false)
                setError('There was an error sending the form')
            })
    }
    return (
        <section
            id="enquire-form"
            className="enquire-form form-dropdown"
            style={{
                backgroundImage: `url(${enquireFormBackgroundImage})`,
            }}
        >
            <div id="contact-form" className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={event => handleOnSubmit(event)}>
                            <h3>Register your interest in PDX today!</h3>
                            {inputFields.map((inputField, index) => (
                                <InputFieldItem
                                    key={index}
                                    index={index}
                                    inputItem={inputField}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                />
                            ))}
                            <div className="row repatcha">
                                <div className="col-md-6">
                                    <div className="recaptcha">
                                        <p>
                                            <input
                                                type="checkbox"
                                                checked={checkbox}
                                                onChange={() =>
                                                    setCheckbox(!checkbox)
                                                }
                                            />
                                            <span>
                                                I accept the Terms and
                                                Conditions of the Privacy Policy
                                            </span>
                                        </p>
                                        <Recaptcha
                                            sitekey={config.RECAPTCHA}
                                            onChange={result =>
                                                result
                                                    ? setRecaptcha(true)
                                                    : setRecaptcha(false)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="submit"
                                        value={
                                            loading && !error
                                                ? 'Loading...'
                                                : 'REGISTER >'
                                        }
                                    />
                                    {error ? (
                                        <span className="submit-error">
                                            {error}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
