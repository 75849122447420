import React, { useState, useEffect } from 'react'

import './style.css'

import { GalleryDTO } from '../../types'
import HeaderImage from './HeaderImage'

interface ImageCarouselProps {
    headerImage: string
    gallery: GalleryDTO | false
}

export default ({ headerImage, gallery }: ImageCarouselProps) => {
    if (!gallery && !headerImage) return <></>
    if (!gallery) return <HeaderImage image={headerImage} />

    const [imageIndex, setImageIndex] = useState<number>(0)
    const [opacity, setOpacity] = useState<number>(0)
    const updateImage = (index: number = imageIndex + 1) => {
        setOpacity(0)
        setTimeout(() => {
            setImageIndex(imageIndex >= gallery.length ? 0 : index)
            setOpacity(1)
        }, 505)
    }
    useEffect(() => {
        setOpacity(1)
    }, [])
    return (
        <div className="house-image-container">
            <div
                className="house-image"
                onClick={() => updateImage()}
                style={{
                    backgroundImage:
                        imageIndex === 0
                            ? `url(${headerImage})`
                            : `url(${gallery[imageIndex - 1].url})`,
                    opacity,
                }}
            />
            <ul className="carousel">
                <li
                    style={{ backgroundImage: `url(${headerImage})` }}
                    onClick={() => updateImage(0)}
                ></li>
                {gallery.map((image, index) => (
                    <li
                        key={index}
                        style={{ backgroundImage: `url(${image.url})` }}
                        onClick={() => updateImage(index + 1)}
                    ></li>
                ))}
            </ul>
        </div>
    )
}
