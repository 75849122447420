import React from 'react'
import { HousePackage } from '../../../types/HomeDesigns'

import './style.css'
import RoomList from '../../../components/Common/RoomList'
import MapView from './MapView'
import { ThemeOptions } from '../../../types'
import HyperLink from '../../../components/Common/HyperLink'

interface PackageLocationMapProps {
    house: HousePackage
    options: ThemeOptions
}

export default ({ house, options }: PackageLocationMapProps) => (
    <section className="package-location-map container">
        <div className="row">
            <div className="col-lg-12">
                <div className="title-container">
                    <p style={{ color: options.theme.color.primary }}>
                        See where your new home is located
                    </p>
                    <h2>{house.name}</h2>
                    <h3 style={{ color: options.theme.color.secondary }}>
                        Lot {house.lotNumber} {house.streetName}, {house.suburb}
                    </h3>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-7">
                <MapView house={house} />
            </div>
            <div className="col-lg-5">
                <div className="house-info">
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url(${house.backgroundImage})`,
                        }}
                    />
                    <div className="content-container">
                        <h2
                            style={{
                                color: options.theme.color.primary,
                            }}
                        >
                            {house.name}
                        </h2>
                        <RoomList rooms={house.rooms} />
                        <HyperLink
                            label={'Find Directions'}
                            href={`https://www.google.com/maps/dir/?api=1&destination=${house.location.lat},${house.location.lng}`}
                            className={'view-directions-button'}
                            newTab={'_blank'}
                            style={{
                                backgroundColor: options.theme.color.primary,
                            }}
                            onHoverStyle={{
                                backgroundColor: options.theme.color.secondary,
                            }}
                        />
                        {/* <OpeningHours hours={openingHours} options={options} /> */}
                        <p className="description">{house.description}</p>
                    </div>
                    <div className="view-package-button">
                        <HyperLink
                            label={'Shop Now'}
                            href={house.deepLink}
                            className="shop-button"
                            newTab="_link"
                            style={{
                                backgroundColor: options.theme.color.secondary,
                            }}
                            onHoverStyle={{
                                backgroundColor: options.theme.color.primary,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
)
