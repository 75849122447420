import React, { useContext } from 'react'
import './style.css'
import { ThemeContext } from '../../../context/theme'
import Button from '../Button'
import { PercentageDTO } from '../../../types'

interface DepositCalculatorProps {
    setValue: Function
    values: PercentageDTO[]
    selectedValue: number
}

export default ({
    setValue,
    values,
    selectedValue,
}: DepositCalculatorProps) => {
    const theme = useContext(ThemeContext)
    return (
        <div
            className="common deposit-calculator"
            style={{ backgroundColor: theme.color.primary }}
        >
            <h4>I have a deposit of</h4>
            <div className="percentage-container">
                <ul>
                    {values.map((percentage, index) => (
                        <li className="percentage">
                            <Button
                                label={percentage.label}
                                onClick={() => setValue(index)}
                                active={index === selectedValue}
                                style={{
                                    color: 'grey',
                                    backgroundColor: `rgb(0, 18, 110)`,
                                }}
                                onHoverStyle={{
                                    color: 'white',
                                    backgroundColor: '#001c99',
                                    boxShadow: `0px 0px 14px -1px rgba(0,19,105,1)`,
                                }}
                            ></Button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
