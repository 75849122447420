import { HomeDesignRequest, RoomType, HomeDesign } from '../types/HomeDesigns'
import {
    HighlightDTO,
    VirtualTourDTO,
    GalleryDTO,
    FlyerDTO,
    FloorplanDTO,
} from '../types'

export default (homeDesign: HomeDesignRequest) => {
    const price: string = homeDesign.acf.price ? homeDesign.acf.price : '0.0'

    let highlights: HighlightDTO[] | false = false
    if (homeDesign.acf.blocks)
        highlights = homeDesign.acf.blocks.map(
            block =>
                ({
                    title: block.title,
                    image: block.image.url,
                    paragraph: block.text,
                } as HighlightDTO)
        )

    let virtualTour: VirtualTourDTO | false = false
    if (homeDesign.acf.virtual_tour && homeDesign.acf.virtual_tour.url)
        virtualTour = {
            title: homeDesign.acf.virtual_tour.title,
            image: homeDesign.acf.virtual_tour.image.url,
            link: homeDesign.acf.virtual_tour.url,
        }

    let floorplan: FloorplanDTO | false = false
    if (homeDesign.acf.floorplan_g && !homeDesign.acf.floorplan_1)
        floorplan = { ground: homeDesign.acf.floorplan_g.url, first: false }
    else if (homeDesign.acf.floorplan_g && homeDesign.acf.floorplan_1)
        floorplan = {
            ground: homeDesign.acf.floorplan_g.url,
            first: homeDesign.acf.floorplan_1.url,
        }

    let gallery: GalleryDTO | false = false
    if (homeDesign.acf.gallery) gallery = homeDesign.acf.gallery

    let flyer: FlyerDTO | false = false
    if (homeDesign.acf.flyer) flyer = homeDesign.acf.flyer.url

    return {
        id: homeDesign.slug,
        name: homeDesign.title.rendered,
        description: homeDesign.acf.stripped_content,
        backgroundImage: homeDesign.image,
        price,
        storeys: homeDesign.acf.storeys,
        package_only: homeDesign.acf.package_only,
        design_name: homeDesign.acf.design_name,
        design_size: homeDesign.acf.design_size,
        size: {
            size: parseInt(homeDesign.acf.house_size),
            lotWidth: parseInt(homeDesign.acf.min_lot_width),
            lotDepth: parseInt(homeDesign.acf.min_lot_depth),
        },
        floorplan,
        highlights,
        virtualTour,
        gallery,
        flyer,
        rooms: {
            bedroom: {
                type: RoomType.Bedroom,
                count: parseInt(homeDesign.acf.bedrooms),
            },
            living_room: {
                type: RoomType.LivingRoom,
                count: parseInt(homeDesign.acf.living),
            },
            bathroom: {
                type: RoomType.Bathroom,
                count: parseInt(homeDesign.acf.bathrooms),
            },
            garage: {
                type: RoomType.Garage,
                count: parseInt(homeDesign.acf.garages),
            },
        },
    } as HomeDesign
}
