import React from 'react'
import { ThemeOptions, HighlightDTO } from '../../types'

interface DesktopProps {
    highlights: HighlightDTO[]
    options?: ThemeOptions
}

export default ({ highlights, options }: DesktopProps) => (
    <div className="row info-container">
        <div className="container">
            {highlights.map((highlight, index) => {
                return index % 2 ? (
                    <div className="row info">
                        <div className="col-lg-6">
                            <div
                                className="image"
                                style={{
                                    backgroundImage: `url(${highlight.image})`,
                                }}
                            />
                        </div>
                        <div className="col-lg-6 text-container">
                            <h2
                                style={{
                                    color: options.theme.color.secondary,
                                }}
                            >
                                {highlight.title}
                            </h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: highlight.paragraph,
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="row info">
                        <div className="col-lg-6 text-container">
                            <h2
                                style={{
                                    color: options.theme.color.secondary,
                                }}
                            >
                                {highlight.title}
                            </h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: highlight.paragraph,
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="image"
                                style={{
                                    backgroundImage: `url(${highlight.image})`,
                                }}
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
)
