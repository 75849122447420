import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import googleMapsStyling from '../../../../utils/googleMapsStyling'
import { HousePackage } from '../../../../types/HomeDesigns'
const mapMarker = require('../../../../images/map-marker.png')

interface MapViewProps {
    google: any
    house: HousePackage
}

export const MapView = ({ google, house }: MapViewProps) => (
    <div className="map-container">
        <Map
            google={google}
            styles={googleMapsStyling as any}
            initialCenter={{
                lat: house.location.lat,
                lng: house.location.lng,
            }}
            zoom={12}
        >
            <Marker
                position={{
                    lat: house.location.lat,
                    lng: house.location.lng,
                }}
                icon={mapMarker}
            />
        </Map>
    </div>
)

export default GoogleApiWrapper({
    apiKey: 'AIzaSyASeDJruEg909cMDOHMrswrgf5gz2niNC4',
})(MapView)
