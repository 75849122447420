import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import { Houses } from '../types/ExternalApi'
import HouseAndLandSummary from '../views/HouseAndLandSummary'
import {
    HousePackage,
    HomeDesignRequest,
    HomeDesign,
} from '../types/HomeDesigns'
import mapHousePackageRequest from '../utils/mapHousePackageRequest'
import { SiteConfig } from '../types'
import mapHomeDesignRequest from '../utils/mapHomeDesignRequest'
import SubscribeForm from '../components/SubscribeForm'

const defaultTheme = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

interface HouseLandSummaryProps {
    pageContext: {
        housePackage: Houses
        config: SiteConfig
        homeDesign: HomeDesignRequest
    }
}

export default ({ pageContext }: HouseLandSummaryProps) => {
    const housePackage: HousePackage = mapHousePackageRequest(
        pageContext.housePackage
    )
    let homeDesign: HomeDesign | false = false
    if (pageContext.homeDesign)
        homeDesign = mapHomeDesignRequest(pageContext.homeDesign)
    return (
        <ThemeProvider value={defaultTheme}>
            <Layout>
                <SEO title="Home Designs Summary" />
                <HouseAndLandSummary
                    housePackage={housePackage}
                    homeDesign={homeDesign}
                    config={pageContext.config}
                />
                <SubscribeForm
                    theme={defaultTheme}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
