import React, { useState } from 'react'
import { Floorplan, ThemeOptions } from '../../types'
import './style.css'
import DownloadLink from '../Common/DownloadLink'
import { Modal, ModalBody } from 'reactstrap'

const enlarge = require('../../images/enlarge.png')

interface FloorplanProps {
    floorplan: Floorplan
    options: ThemeOptions
}

export default ({ floorplan, options }: FloorplanProps) => {
    if (!floorplan.images.firstFloor && !floorplan.images.groundFloor)
        return <></>
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedFloorplanImage, setSelectedFloorplanImage] = useState<
        boolean
    >(false)
    const showModalWithImage = (choice: boolean) => {
        setSelectedFloorplanImage(choice)
        setShowModal(!showModal)
    }
    return (
        <section className="floorplan-overview container">
            <div className="text-header">
                <div className="floorplan-title">
                    <h2 style={{ color: options.theme.color.primary }}>
                        {floorplan.title}
                    </h2>
                </div>
                {/* <div className="dimensions">
                    <span>Home size {floorplan.dimensions.size}</span>
                    <span>Min. lot width {floorplan.dimensions.minWidth}</span>
                    <span>
                        Min. lot depth 17.0{floorplan.dimensions.minDepth}
                    </span>
                </div> */}
            </div>
            <div className="image-container">
                {floorplan.images.groundFloor ? (
                    <div className="ground-floor">
                        <img
                            src={floorplan.images.groundFloor}
                            width={400}
                            height={'auto'}
                            alt={'ground floor floorplan'}
                        />
                        <div
                            className="enlarge-floorplan"
                            onClick={() => showModalWithImage(false)}
                        >
                            <img
                                src={enlarge}
                                width={25}
                                height={'auto'}
                                alt={'enlarge button'}
                            />
                            <span>Ground floor</span>
                        </div>
                    </div>
                ) : null}
                {floorplan.images.firstFloor ? (
                    <div className="first-floor">
                        <img
                            src={floorplan.images.firstFloor}
                            width={400}
                            height={'auto'}
                            alt={'first floor floorplan'}
                        />
                        <div
                            className="enlarge-floorplan"
                            onClick={() => showModalWithImage(true)}
                        >
                            <img
                                src={enlarge}
                                width={25}
                                height={'auto'}
                                alt={'enlarge button'}
                            />
                            <span>First floor</span>
                        </div>
                    </div>
                ) : null}
            </div>
            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                onClick={() => setShowModal(!showModal)}
                className="floorplan-modal"
                style={{ marginTop: 100 }}
            >
                <ModalBody>
                    <img
                        src={
                            selectedFloorplanImage &&
                            floorplan.images.firstFloor
                                ? floorplan.images.firstFloor
                                : floorplan.images.groundFloor
                        }
                    />
                </ModalBody>
            </Modal>
            <DownloadLink />
        </section>
    )
}
