import React, { useContext, useState } from 'react'
import { HousePackage, HomeDesign } from '../../types/HomeDesigns'
import './style.css'
import { ThemeContext } from '../../context/theme'
import { HouseCardInfo } from './HouseCardInfo'
import { Floorplan, SiteConfig } from '../../types'
import FloorplanOverview from '../../components/FloorplanOverview'
import PackageLocationMap from './PackageLocationMap'
import ImageCarousel from '../../components/ImageCarousel'
import TakeTour from '../../components/TakeTour'
import EnquireForm from '../../components/EnquireForm'
import { Link } from 'react-scroll'
import HouseHighlights from '../../components/HouseHighlights'

interface HouseAndLandSummaryProps {
    housePackage: HousePackage
    homeDesign?: HomeDesign | false
    config: SiteConfig
}

export default ({
    housePackage,
    homeDesign = false,
    config,
}: HouseAndLandSummaryProps) => {
    const theme = useContext(ThemeContext)
    const [active, setActive] = useState<number>(0)
    const [onShopButtonHover, setOnShopButtonHover] = useState<boolean>(false)
    const [onEnquireButtonHover, setonEnquireButtonHover] = useState<boolean>(
        false
    )
    console.log(housePackage.floorplan)
    const floorplan: Floorplan = {
        title: housePackage.name,
        dimensions: {
            size: housePackage.size.size,
            minDepth: housePackage.size.lotDepth,
            minWidth: housePackage.size.lotWidth,
        },
        images: {
            groundFloor: housePackage.floorplan.ground,
            firstFloor: housePackage.floorplan.first,
        },
    }
    return (
        <section className="house-and-land-summary container-fluid">
            <div className="row house-info-container">
                <div className="col-lg-8">
                    <ImageCarousel
                        headerImage={housePackage.backgroundImage}
                        gallery={homeDesign ? homeDesign.gallery : false}
                    />
                </div>
                <div className="house-info col-lg-4">
                    <HouseCardInfo
                        house={housePackage}
                        options={{ theme }}
                        active={active}
                        showDepositCalculator={true}
                        setActive={setActive}
                    />
                    <a
                        style={{
                            backgroundColor: onShopButtonHover
                                ? theme.color.primary
                                : theme.color.secondary,
                        }}
                        onMouseEnter={() => setOnShopButtonHover(true)}
                        onMouseLeave={() => setOnShopButtonHover(false)}
                        href={housePackage.deepLink}
                        className="shop-button"
                        target="_blank"
                    >
                        Shop Now
                    </a>
                    <Link
                        style={{
                            backgroundColor: onEnquireButtonHover
                                ? theme.color.primary
                                : 'transparent',
                            border: onEnquireButtonHover
                                ? `1px solid ${theme.color.primary}`
                                : `1px solid ${theme.color.secondary}`,
                            color: onEnquireButtonHover
                                ? 'white'
                                : theme.color.secondary,
                        }}
                        onMouseEnter={() => setonEnquireButtonHover(true)}
                        onMouseLeave={() => setonEnquireButtonHover(false)}
                        to="enquire-form"
                        className="enquire-button"
                        offset={0}
                        smooth={true}
                        duration={1000}
                    >
                        Enquire Now
                    </Link>
                </div>
            </div>
            <HouseHighlights
                highlights={homeDesign ? homeDesign.highlights : false}
                options={{ theme }}
            />
            <div className="row">
                <FloorplanOverview floorplan={floorplan} options={{ theme }} />
            </div>
            <div className="row">
                <TakeTour
                    tour={homeDesign ? homeDesign.virtualTour : false}
                    options={{ theme }}
                />
            </div>
            <div className="row">
                <PackageLocationMap
                    house={housePackage}
                    homeDesign={homeDesign}
                    options={{ theme }}
                />
            </div>
            <div className="row">
                <EnquireForm
                    options={{ theme }}
                    config={config}
                    home={housePackage.name}
                />
            </div>
        </section>
    )
}
