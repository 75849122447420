import React, { useState } from 'react'
import { HousePackage } from '../../../types/HomeDesigns'
import { ThemeOptions, PercentageDTO } from '../../../types'
import RoomList from '../../../components/Common/RoomList'
import './style.css'
import DepositCalculator from '../../../components/Common/DepositCalculator'
import Button from '../../../components/Common/Button'
import formatHousePrice from '../../../utils/formatHousePrice'
import calculateMonthlyFee from '../../../utils/calculateMonthlyFee'
import HyperLink from '../../../components/Common/HyperLink'

interface HouseCardInfoProps {
    house: HousePackage
    showDepositCalculator?: boolean
    showCtaButton?: boolean
    options: ThemeOptions
    active: number
    setActive: Function
}

export const HouseCardInfo = ({
    house,
    options,
    showDepositCalculator = false,
    showCtaButton = false,
}: HouseCardInfoProps) => {
    const monthlyPrice = parseInt(calculateMonthlyFee(house.price))
    const percentages: PercentageDTO[] = [
        { label: '5%', value: 0.95 },
        { label: '10%', value: 0.9 },
        { label: '20%', value: 0.8 },
    ]
    const [percentageSelection, setPercentageSelection] = useState<number>(0)
    const weeklyFee = showDepositCalculator
        ? Math.round(monthlyPrice * percentages[percentageSelection].value)
        : monthlyPrice
    const housePrice = formatHousePrice(house.price)
    return (
        <div className="house-card-info">
            <span
                className="house-type-label"
                style={{ backgroundColor: options.theme.color.primary }}
            >
                House & Land Package
            </span>
            <div className="top white">
                <h3 style={{ color: options.theme.color.primary }}>
                    {house.name}
                </h3>
                <RoomList rooms={house.rooms} />
                <p>
                    <span style={{ color: options.theme.color.secondary }}>
                        Lot {house.lotNumber} {house.streetName},{' '}
                    </span>
                    {house.suburb}
                </p>
            </div>

            <div className="bottom">
                {house.package_only ? (
                    <></>
                ) : (
                    <>
                        <span className="price">
                            from{' '}
                            <span className="red big-text">${housePrice}</span>
                        </span>
                        <span className="monthly-price">
                            or <span className="red">${weeklyFee}</span> a week
                            {
                                <HyperLink
                                    label="*"
                                    style={{
                                        color: options.theme.color.primary,
                                    }}
                                    onHoverStyle={{
                                        color: options.theme.color.secondary,
                                    }}
                                    href={
                                        'https://porterdavis.com.au/disclaimer/'
                                    }
                                />
                            }
                        </span>
                    </>
                )}
            </div>
            {showCtaButton ? (
                <Button
                    label={'Plus upto $20k in upgrades'}
                    onClick={() => {}}
                    style={{
                        color: 'white',
                        backgroundColor: options.theme.color.primary,
                        border: `1px solid ${options.theme.color.primary}`,
                    }}
                    onHoverStyle={{
                        color: options.theme.color.primary,
                        backgroundColor: 'transparent',
                        border: `1px solid ${options.theme.color.primary}`,
                    }}
                    className="cta-button"
                />
            ) : (
                <></>
            )}
            {showDepositCalculator ? (
                <DepositCalculator
                    values={percentages}
                    setValue={setPercentageSelection}
                    selectedValue={percentageSelection}
                />
            ) : (
                <></>
            )}
        </div>
    )
}
