import React from 'react'

interface HeaderImageProps {
    image: string
}

export default ({ image }: HeaderImageProps) => (
    <div className="house-image-container only-header-image">
        <div
            className="house-image"
            style={{
                backgroundImage: `url(${image})`,
            }}
        />
    </div>
)
